.validation-error-message {
  color: red;
  font-size: .8rem;
  margin-top: 5px;
}

.font-sans {
  font-family: Nunito, sans-serif;
  color: rgb(253, 253, 253);
  font-size: 1.5rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.font-semibold {
  font-weight: 600;
}

.max-h-10 {
  max-height: 2.5rem;
}

.h-10 {
  height: 2.5rem;
}